import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Main from '../views/Main.vue';
import Step2 from '../views/Step2.vue';
import Step3 from '../views/Step3.vue';
import StepFin from '../views/StepFin.vue';
import Ayuda from '../views/Ayuda.vue';

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
  },
  {
    path: '/step2',
    name: 'step2',
    component: Step2,
  },
  {
    path: '/step3',
    name: 'step3',
    component: Step3,
  },
  {
    path: '/stepfin',
    name: 'stepfin',
    component: StepFin,
  },
  {
    path: '/ayuda',
    name: 'ayuda',
    component: Ayuda,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }

  if (store.state.auth.userLoggedIn) {
    next();
  } else {
    next({ name: 'login' });
  }
});

export default router;
