<template>
  <div class="some">
    <div class="some__bar">
      <!-- Share on Twitter -->
      <a
        href="https://twitter.com/intent/tweet?text=VELTIUM%20te%20regala%20un%20descuento%20de%20hasta%200%2C10%20%E2%82%AC%20por%20cada%20kWh%20en%20la%20red%20de%20Zunder.%20Consigue%20tu%20c%C3%B3digo%20de%20descuento%20en%20https%3A%2F%2Fpromozunder.veltium.com"
        target="_blank"
      >
        <img
          class="some__bar__img"
          alt="Twitter"
          src="assets/x-twitter.svg"
          width="35"
          height="35"
        />
      </a>

      <!-- Facebook -->
      <div style="display:none">
        <div id="fb-root"></div>
        <div
          class="fb-share-button"
          :data-href="shareUrl"
          data-layout=""
          data-size=""
        >
          <a
            target="_blank"
            :href="facebookShareLink"
            class="fb-xfbml-parse-ignore"
          >
            <img
              class="some__bar__img"
              alt="Facebook"
              src="assets/square-facebook.svg"
              width="35"
              height="35"
            />
          </a>
        </div>
      </div>

      <!-- LinkedIn -->
      <a :href="linkedinShareLink" target="_blank">
        <img
          class="some__bar__img"
          alt="LinkedIn"
          src="assets/linkedin.svg"
          width="35"
          height="35"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shareUrl: 'https://promozunder.veltium.com',
      isFacebookSdkLoaded: true,
    };
  },
  computed: {
    facebookShareLink() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.shareUrl
      )}&amp;src=sdkpreparse`;
    },
    linkedinShareLink() {
      return `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        this.shareUrl
      )}&title=VELTIUM%20te%20regala%20un%20descuento%20de%20hasta%200%2C10%20%E2%82%AC%20por%20cada%20kWh%20en%20la%20red%20de%20Zunder.%20Consigue%20tu%20c%C3%B3digo%20de%20descuento%20en%20${encodeURIComponent(
        this.shareUrl
      )}&source=veltium.com`;
    },
  },
  mounted() {
    this.loadFacebookSdk();
  },
  methods: {
    loadFacebookSdk() {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        FB.init({
          appId: 'your-app-id', // Reemplazar con tu App ID de Facebook
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v20.0',
        });
        this.isFacebookSdkLoaded = true;
      };
      script.onerror = () => {
        console.error('Failed to load Facebook SDK');
      };
      document.head.appendChild(script);
    },
  },
};
</script>

<style scoped>
.some__bar {
  display: flex;
  gap: 10px;
}
.some__bar__img {
  cursor: pointer;
}
</style>
