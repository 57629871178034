<template>
  <div id="main" class="main">
    <Header />
    <div class="main__contenido">
      <div class="eslogan">
          {{ $t('Main.eslogan') }}
        </div>
      <div v-show="!appEnabled" class="parrafo">
        {{ $t('Main.alternateSlogan') }}
      </div>
        <div class="parrafo" v-show="appEnabled">
          {{ $t('Main.parrafo1') }} <b>{{$t('Main.veltiumApp')}}</b> {{ $t('Main.parrafo1a') }}
        </div>
        <div class="parrafo__header" v-show="appEnabled && !loading">{{ $t('Main.parrafo2') }}</div>
        <div class="parrafo__header" v-show="appEnabled && loading">{{ $t('Others.checking') }}</div>

        <div class="input" v-show="appEnabled" :disabled="loading" >
          <div>&nbsp;</div>

          <div class="input__caja">
            <input
              class="input__caja__inputserial"
              type="text"
              :placeholder="$t('Main.inputPlaceholder')"
              maxlength="12"
              v-model="serialNumber"
              @keyup.enter="goToNextStep"
            >
          </div>

          <div class="boton__tip" v-show="appEnabled"  >
            <img @mouseover="toggleTip('tip_serialnumber')" @mouseout="toggleTip('tip_serialnumber')" class="boton__tip__img" src="/assets/ico-tip.svg" width="24" height="24" alt="">
            <div :class="['boton__tip__overlay', tipSerialNumber ? '' : 'none']" id="tip_serialnumber">
              <div class="boton__tip__overlay__grid">
                <div class="boton__tip__overlay__grid__left serialnumber">&nbsp;</div>
                <div class="boton__tip__overlay__grid__right">
                  <span>{{ $t('Main.tipText') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div>&nbsp;</div>

        <div class="boton" v-show="appEnabled">

          <div>&nbsp;</div>

          <div class="boton__caja" :disabled="loading" >
            <button @click="goToNextStep" class="boton__button">{{ $t('Main.continueButton') }}</button>
          </div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import { verifyCharger } from '../includes/apiService';
import { mapMutations } from 'vuex';

export default {
  name: 'Main',
  components: {
    Header,
  },
  data() {
    return {
      tipSerialNumber: false,
      serialNumber: '',
      isCaptchaVerified: false,
      recaptchaSiteKey:  '6LcU6BUqAAAAAP3tiRell48sPzfX-qARjY_8llBg',
      recaptchaResponse: '',
      appEnabled: process.env.VUE_APP_ENABLED === 'true',
      loading: false,
    };
  },
  methods: {
    ...mapMutations(['setSerialNumber', 'setError', 'setResendEmail', 'setCodeData', 'setErrorType1']),
    toggleTip(id) {
      this.tipSerialNumber = !this.tipSerialNumber;
    },
    onCaptchaVerified(response) {
      console.log('Captcha verificado:', response);
      this.isCaptchaVerified = true;
      this.recaptchaResponse = response;
    },
    async goToNextStep() {
      console.log('Verificando el número de serie:', this.serialNumber);

      if (this.serialNumber.length < 8) {
        console.error('Número de serie inválido');
        const errorMessage = this.$t('Main.serialError');
        this.setError({ error: true, message: errorMessage });
        // log state
        console.log('Error STATE MAIN:', this.error);
        this.setErrorType1(true);
        await this.$nextTick();
        this.$router.push('step2');
        return; // Asegúrate de salir de la función para que no continúe con el resto del código
      }

      try {
        const response = await this.checkCharger(this.serialNumber);
        console.log(response);

        if (response.result === 'success') {
          // comparobar si response.data tiene la siguiente estructura
          // { "code": "xxxx", "mail": "albertopena@veltium.com", "user": "BEwkg5cutjWy4PjP34fLkq6SsIe2" }
          if (response.data && response.data.code) {
            this.setSerialNumber(this.serialNumber);
            this.setResendEmail(false);
            this.setCodeData(response.data);
            await this.$nextTick();
            this.$router.push('step3');
            return;
          }
          // comprobar que el caragdor tenga usuarios asociados
          // si users es: { policy: { policy: 'all' } } no tiene usuarios asociados

          const users = response.data.device.users;
          // quitar key de policy si existe
          if (users.policy) {
            delete users.policy;
          }
          console.log('Usuarios asociados:', users);
          // ver el número de usuarios asociados (keys de un objeto)
          const usersKeys = Object.keys(users).length;
          console.log('Número de usuarios asociados:', usersKeys);

          if (users && (usersKeys === 0)) {
            console.log('El cargador no tiene usuarios asociados');
            const errorMessage = this.$t('Main.serialError2');
            this.setError({ error: true, message: errorMessage });
            this.setErrorType1(false);
            await this.$nextTick();
            this.$router.push('step2');
            return;
          }
          // Todo OK
          this.setSerialNumber(this.serialNumber);
          await this.$nextTick();
          this.$router.push('step3');
        } else {
          console.error("Error verificando el cargador 1",response.message);
          const errorMessage = response.message || this.$t('Main.serialError');
          this.setSerialNumber(this.serialNumber);
          this.setError({ error: true, message: errorMessage });
          this.setErrorType1(false);
          this.set
          await this.$nextTick();
          this.$router.push('step2');
        }
      } catch (error) {
        console.error('Error verificando el cargador 2:', error);
        const errorMessage = error.response?.data || this.$t('Main.verificationError');
        this.setError({ error: true, message: errorMessage });
        this.setErrorType1(false);

        await this.$nextTick();
        // wait more to be sure
        this.$router.push('step2');
      }
    },
    async checkCharger(serialNumber) {
        // Disable input and button
        this.loading = true;
        // hourglass


        const result = await verifyCharger(serialNumber);

        // Enable input and button
        this.loading = false;
        return result;

    },
    mountRecaptcha() {
      if (this.$refs.recaptcha) {
        window.grecaptcha.render(this.$refs.recaptcha, {
          sitekey: this.recaptchaSiteKey,
          callback: this.onCaptchaVerified,
        });
      } else {
        console.error('Elemento reCAPTCHA no encontrado');
      }
    },
  },
  created() {
    this.setSerialNumber('');
    console.log(process.env.VUE_APP_RECAPTCHA_SITE_KEY);
  },
};
</script>

<style scoped>
.g-recaptcha {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
</style>
