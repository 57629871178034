<template>
  <div class="footer">
    <div class="footer__bar">
      <div class="footer__bar__left">
        &copy; Veltium Smart Chargers S.L.
      </div>
      <div class="footer__bar__right">
        <div class="footer__bar__right__item">
          <a href="https://www.veltium.com/bases-promo-zunder/" target="_blank">Bases</a>
        </div>
        <div class="footer__bar__right__item">|</div>
        <div class="footer__bar__right__item">
          <a href="https://www.veltium.com/cookies/" target="_blank">Cookies</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
/* Agrega aquí tus estilos locales para Footer */
</style>
