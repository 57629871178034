<template>
  <div id="main" class="main">
    <Header />

    <div class="main__contenido">
      <map style="display:none" name="image-map">
        <area href="/" coords="0,0,160,110" shape="rect">
        <area coords="230,0,350,110" shape="rect">
      </map>
      <div class="pasos">
        <img usemap="#image-map" class="pasos__img" :src="pasosSource" width="606" height="116" alt="" />
      </div>
      <div v-if="error && !loading" class="eslogan error" >{{ errorText }}</div>
      <!-- <div v-if="error && !loading" class="parrafo error" style="margin-top:-2.5rem; margin-bottom:0.5rem;">
        {{ $t('Step2.errorParrafo') }}
      </div> -->
      <div class="eslogan" v-if="!error && resendEmail!==true && !loading">{{ $t('Step3.enterEmail') }}</div>
      <div class="eslogan" v-if="!error && resendEmail===true && !loading">{{ $t('Step3.resendEmail') }}</div>
      <div class="parrafo" style="margin-top:-2.5rem; margin-bottom:0.5rem;">&nbsp;</div>
      <div class="parrafo__header" v-show="loading">{{ $t('Others.checking') }}</div>
      <div class="input" v-show="resendEmail!==true">
        <div>&nbsp;</div>
        <div class="input__caja" :disabled="loading">
          <input v-model="email" class="input__caja__inputemail" type="email"
           placeholder="aaa@bbb.com" maxlength="100" @keyup.enter="goToNextStep"/>
        </div>

        <div class="boton__tip">
          <img @mouseover="toggleTip('tip_email')" @mouseout="toggleTip('tip_email')" class="boton__tip__img" src="assets/ico-tip.svg" width="24" height="24" alt="">
          <div :class="['boton__tip__overlay', tipEmail ? '' : 'none']" id="tip_email">
            <div class="boton__tip__overlay__grid">
              <div class="boton__tip__email__overlay__grid__left appVeltium"></div>
              <div class="boton__tip__overlay__grid__right">
                <span>{{ $t('Step3.tipEmail') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="boton" :disabled="loading">
        <div>&nbsp;</div>
        <div class="boton__caja">
          <button @click="goToNextStep" class="boton__button">{{ $t('Step3.continueButton') }}</button>
        </div>
        <div>&nbsp;</div>
      </div>
      <div class="parrafo__support" v-if="error">{{ $t('Others.support') }} usersupport@veltium.com</div>
    </div>

  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import { verifyEmail } from '../includes/apiService';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Step3',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      tipEmail: false,
      email: '',
      error: false,
      errorText: '',
      loading: false,

    };
  },
  computed: {
    ...mapState(['serialNumber','resendEmail','codeData']),
    pasosSource() {
      return `assets/pasos2-${this.$i18n.locale}.svg`;
    },
  },
  methods: {
    ...mapMutations(['setSerialNumber', 'setError', 'setResendEmail', 'setCodeData','setProcessFinalized']),
    toggleTip() {
      this.tipEmail = !this.tipEmail;
    },
    async goToNextStep() {
      try {
        // get the codeData from the store
        const codeData = this.codeData;
        // Email to lower case
        this.email = this.email.toLowerCase();

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.email)) {
          this.error = true;
          this.errorText = this.$t('Step3.errorEmailFormat');
          return;
        }

        if (codeData.code && codeData.mail) {
          // El seral number ya se ha comprobado y tiene un código asociado

          console.log("Comparación de email:", this.email, codeData.mail);
          if (this.email !== codeData.mail) {
            this.error = true;
            this.errorText = this.$t('Step3.errorEmail2');
            console.log('Otro usuario de este cargador ya solicitó el código promocional.');
            return;
          }
          else {
            if (!this.resendEmail) {
                console.log('Email correcto redirigir a mismo paso pero con resendEmail=true');
                // El email es correcto redirigir a mismo paso pero con resendEmail=true
                this.setSerialNumber(this.serialNumber);
                this.setResendEmail(true);
                this.error = false;
                await this.$nextTick();
                this.$router.push('step3');
                return;
             }
             else {
                // El email es correcto y se ha solicitado reenviar el email
                console.log('Verificando el email en API  :', this.email);
                const result = await this.checkEmail(this.email, this.serialNumber, this.$i18n.locale, this.resendEmail);
                console.log(result);

                if (result.result==='success') {
                  this.setProcessFinalized(true);
                  this.$router.push('stepFin');
                } else {
                  this.error = true;
                  this.errorText = '';
                  console.error(result.message);
                }
             }
          }
        }
        else {
          // El email es correcto y se ha solicitado reenviar el email
          console.log('Verificando el email en API  :', this.email);
          const result = await this.checkEmail(this.email, this.serialNumber, this.$i18n.locale, this.resendEmail);
          console.log(result);
          if (result.result==='success') {
            this.setProcessFinalized(true);
            this.$nextTick();
            this.$router.push('stepFin');
          } else {
            this.error = true;
            this.errorText = '';
            console.error(result.message);
          }
  }


      } catch (error) {
        this.error = true;
        this.errorText = this.$t('Step3.errorEmail');
        console.error('Error verificando el email:', error);
      }
    },
    async checkEmail(email, serialNumber, locale) {
      try {
        this.loading = true;
        const result = await verifyEmail(email, serialNumber, locale, this.resendEmail);
        this.loading = false;
        return result;
      } catch (error) {
        this.loading = false;
        console.error('Error verificando el email:', error);
        throw error;
      }
    }
  },
  created() {
    if (this.resendEmail) {
      console.log('Resend email:', this.codeData);
      this.email = this.codeData.mail;

    }
    if (!this.serialNumber) {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
</style>
