<template>
  <div id="main" class="main">
    <Header />
    <div class="main__contenido">
      <map style="display:none" name="image-map">
        <area href="/" coords="0,0,160,110" shape="rect">
        <area coords="230,0,350,110" shape="rect">
      </map>
      <div class="pasos">
        <img usemap="#image-map" class="pasos__img" :src="pasosSource" width="606" height="116" alt="">
      </div>
      <div v-if="error && errorType1 &&!loading" class="eslogan error">{{ $t('Step2.errorEslogan') }}</div>
      <div v-if="error && !errorType1 &&!loading" class="eslogan error">{{ $t('Step2.errorEslogan2') }}</div>
      <div v-if="error && errorType1 &&!loading" class="parrafo error" style="margin-top:-2.5rem; margin-bottom:0.5rem;">
        {{ $t('Step2.errorParrafo') }}
      </div>
      <div class="parrafo__header" v-show="loading">{{ $t('Others.checking') }}</div>
      <div class="input" :disabled="loading">
        <div>&nbsp;</div>
        <div class="input__caja">
          <input
            class="input__caja__inputserial"
            type="text"
            :placeholder="$t('Step2.inputPlaceholder')"
            maxlength="12"
            v-model="serialNumberInput"
            @keyup.enter="goToNextStep"
          >
        </div>
        <div class="boton__tip">
          <img @mouseover="toggleTip('tip_serialnumber')" @mouseout="toggleTip('tip_serialnumber')" class="boton__tip__img" src="assets/ico-tip.svg" width="24" height="24" alt="">
          <div :class="['boton__tip__overlay', tipSerialNumber ? '' : 'none']" id="tip_serialnumber">
            <div class="boton__tip__overlay__grid">
              <div class="boton__tip__overlay__grid__left serialnumber">&nbsp;</div>
              <div class="boton__tip__overlay__grid__right">
                <span>{{ $t('Step2.tipText') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="boton" :disabled="loading">
        <div>&nbsp;</div>
        <div class="boton__caja">
          <button @click="goToNextStep" class="boton__button">{{ $t('Step2.continueButton') }}</button>
        </div>
        <div>&nbsp;</div>
      </div>
      <div class="parrafo__support" v-if="error">{{ $t('Others.support') }} usersupport@veltium.com</div>
    </div>

  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import { verifyCharger } from '../includes/apiService';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Step2',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      tipSerialNumber: false,
      serialNumberInput: '',
      serialNumber: this.$store.state.serialNumber,
      error: this.$store.state.error,
      errorMessage: this.$store.state.errorMessage,
      completeError: this.$store.state.completeError,
      errorType1: this.$store.state.errorType1,
      loading: false,

    };
  },
  computed: {
    // ...mapState(['serialNumber'], ['error', 'errorMessage', 'completeError']),
    pasosSource() {
      return `assets/pasos1-${this.$i18n.locale}.svg`;
    },
    errorType1: {
      get() {
        return this.$store.state.errorType1;
      },
      set(value) {
        this.$store.commit('setErrorType1', value);
      },
    },
    error: {
      get() {
        return this.$store.state.error;
      },
      set(value) {
        this.$store.commit('setError', value);
      },
    },
    errorMessage: {
      get() {
        return this.$store.state.errorMessage;
      },
      set(value) {
        this.$store.commit('setErrorMessage', value);
      },
    },
    completeError: {
      get() {
        return this.$store.state.completeError;
      },
      set(value) {
        this.$store.commit('setCompleteError', value);
      },
    },

  },
  methods: {
    ...mapMutations(['setSerialNumber', 'setError', 'setResendEmail', 'setCodeData', 'setErrorType1']),
    toggleTip() {
      this.tipSerialNumber = !this.tipSerialNumber;
    },
    async goToNextStep() {
      try {
        if (this.serialNumberInput.length < 10) {
          console.error('Número de serie inválido');
          const errorMessage = this.$t('Main.serialError');
          this.setError({ error: true, message: errorMessage });
          this.setErrorType1(true);
          // log state
          console.log('Error STATE MAIN:', this.error);
          this.setErrorType1(true);
          await this.$nextTick();
          this.$router.push('step2');
          return; // Asegúrate de salir de la función para que no continúe con el resto del código
        }
        const response = await this.checkCharger(this.serialNumberInput);
        console.log(response);

        if (response.result === 'success') {
          // comparobar si response.data tiene la siguiente estructura
          // { "code": "xxxx", "mail": "albertopena@veltium.com", "user": "BEwkg5cutjWy4PjP34fLkq6SsIe2" }
          if (response.data && response.data.code) {
            this.setSerialNumber(this.serialNumberInput);
            this.setResendEmail(false);
            this.setCodeData(response.data);
            this.setErrorType1(false);
            await this.$nextTick();
            this.$router.push('step3');
            return;
          }
          // comprobar que el caragdor tenga usuarios asociados
          // si users es: { policy: { policy: 'all' } } no tiene usuarios asociados

          const users = response.data.device.users;
          // quitar key de policy si existe
          if (users.policy) {
            delete users.policy;
          }
          console.log('Usuarios asociados:', users);
          // ver el número de usuarios asociados (keys de un objeto)
          const usersKeys = Object.keys(users).length;
          console.log('Número de usuarios asociados:', usersKeys);

          if (users && (usersKeys === 0)) {
            console.log('El cargador no tiene usuarios asociados');
            const errorMessage = this.$t('Main.serialError2');
            this.setError({ error: true, message: errorMessage });
            this.setErrorType1(false);
            await this.$nextTick();
            this.$router.push('step2');
            return;
          }
          this.setSerialNumber(this.serialNumberInput);
          await this.$nextTick();
          this.error = false;
          this.$router.push('step3');
        } else {
          this.setErrorType1(false);
          console.error(response.message);
          this.error = true;
        }
      } catch (error) {
        this.setErrorType1(false);
        console.error('Error verificando el cargador:', error);
        this.error = true;
      }
    },
    async checkCharger(serialNumber) {
      try {
        this.loading = true;

        const result = await verifyCharger(serialNumber);
        this.loading = false;
        return result;
      } catch (error) {
        console.error('Error verificando el cargador:', error);
        this.loading = false;
        throw error;
      }
    },
  },
  created() {
    // log store complete state
    console.log('Store Error', this.$store.state.error);
    console.log('Store ErrorMessage', this.$store.state.errorMessage);



  },
};
</script>

<style scoped>
/* Tu CSS aquí */
</style>
