// src/apiService.js
import axios from 'axios';

// Crear una instancia de axios con configuración por defecto
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: Number(process.env.VUE_APP_API_TIMEOUT), // 70 segundos si VUE_APP_API_TIMEOUT es 70000
  headers: {
    'Content-Type': 'application/json',
  },
});

// Función para hacer una solicitud GET
const verifyCharger = async (vcd) => {
  try {
    console.log(process.env.VUE_APP_API_BASE_URL);
    const response = await axiosInstance.get(`/chargers/verify/${vcd}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Función para hacer una solicitud POST
const verifyEmail = async (email, vcd, locale, resend) => {
  const data = { email, vcd, locale, resend };
  try {
    const response = await axiosInstance.post('/chargers/verify/email', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Exportar las funciones para poder usarlas en otros archivos
export { verifyCharger, verifyEmail };

