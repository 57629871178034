<template>
  <div id="main_ayuda" class="main">
    <div class="main__cabecera">
      <div>&nbsp;</div>
      <div class="main__cabecera__logo"><img :src="logoVeltium" alt="Logo Veltium" width="262" height="68"></div>
      <div>&nbsp;</div>
      <div class="main__cabecera__logo"><img :src="logoZunder" alt="Logo Zunder" width="262" height="68"></div>
      <div class="main__cabecera__help"><img class="invisible" :src="helpIcon" alt="Ayuda" width="45" height="45"></div>
    </div>
    <div class="main__contenido">
      <div class="eslogan">{{ $t('Ayuda.eslogan') }}</div>
      <div class="parrafo__header">{{ $t('Ayuda.parrafo1') }}</div>
      <div class="parrafo__header">{{ $t('Ayuda.parrafo2') }}</div>
      <div class="parrafo lista">
        <ol>
          <li>{{ $t('Ayuda.paso1') }} <a href="https://www.qrcodechimp.com/page/sbzng9fp2lm6?v=chk1721029420" target="_blank">{{$t('Ayuda.appVeltium')}}</a>, {{ $t('Ayuda.paso1b') }}</li>
          <li>{{ $t('Ayuda.paso2') }}</li>
          <li>{{ $t('Ayuda.paso3') }}</li>
          <li>{{ $t('Ayuda.paso4') }} <a href="https://app.zunder.com/" target="_blank">{{$t('Ayuda.appZunder')}}</a></li>
          <li>{{ $t('Ayuda.paso5') }}</li>
          <li>{{ $t('Ayuda.paso6') }} <a href="https://www.zunder.com/mapa-de-ubicaciones/" target="_blank">{{ $t('Ayuda.zunderNetwork') }}</a></li>
        </ol>
      </div>
      <div class="boton">
        <div>&nbsp;</div>
        <div class="boton__caja"><button @click="goBack" class="boton__button--modal">{{ $t('Ayuda.volver') }}</button></div>
        <div>&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ayuda',
  data() {
    return {
      logoVeltium: "/assets/logo-veltium.svg",
      logoZunder: "/assets/logo-zunder.svg",
      helpIcon: "/assets/ico-ayuda.svg"
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.main__ayuda {
  display: block;
}

/* Add other styles according to your needs */
</style>
