<template>
  <div id="main" class="main">
    <Header />
    <div class="main__contenido">

      <map style="display:none" name="image-map">
        <area href="/" coords="0,0,160,110" shape="rect">
        <area href="step3" coords="230,0,350,110" shape="rect">
      </map>

      <div class="pasos">
        <img usemap="#image-map" class="pasos__img" :src="pasosSource" width="606" height="116" alt="">
      </div>

      <div class="eslogan">{{ $t('StepFin.congratulations') }}</div>
      <div class="parrafo" style="margin-top:-2.5rem; margin-bottom:0.5rem;">
        {{ $t('StepFin.emailMessage') }}
      </div>

      <div class="parrafo__header">{{ $t('StepFin.sharePromotion') }}</div>

      <SocialShare />
    </div>

  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import SocialShare from '../components/SocialShare.vue';

export default {
  name: 'StepFin',
  components: {
    Header,
    Footer,
    SocialShare,
  },
  data() {
    return {
      tipSerialNumber: false,


    };
  },
  computed: {
    pasosSource() {
      return `assets/pasos3-${this.$i18n.locale}.svg`;
    },
  },
  methods: {},
  created() {
    if (!this.$store.state.processFinalized) {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
/* Tu CSS aquí */
</style>
