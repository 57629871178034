// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    serialNumber: '',
    error: false,
    errorMessage: '',
    completeError: {},
    resendEmail: false,
    codeData: {},
    errorType1: true,
    processFinalized: false,
  },
  mutations: {
    setSerialNumber(state, serialNumber) {
      state.serialNumber = serialNumber;
    },
    setError(state, { error, message, completeError }) {
      console.log('setting error in mutation', error);
      state.error = error;
      state.errorMessage = message || '';
      state.completeError = completeError || {};
    },
    setResendEmail(state, resendEmail) {
      state.resendEmail = resendEmail;
    },
    setCodeData(state, codeData) {
      state.codeData = codeData;
    },
    setErrorType1(state, errorType1) {
      state.errorType1 = errorType1;
    },
    setProcessFinalized(state, processFinalized) {
      state.processFinalized = processFinalized;
    },
  },
  actions: {},
  modules: {}
});
