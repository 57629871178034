<template>
  <div class="main__cabecera__idiomas">
    <a
      href="#"
      class="main__cabecera__idiomas__link"
      :class="{ selected: currentLocale === 'es' }"
      @click.prevent="setLocale('es')"
    >ES</a>
    <a
      href="#"
      class="main__cabecera__idiomas__link"
      :class="{ selected: currentLocale === 'en' }"
      @click.prevent="setLocale('en')"
    >EN</a>
    <a
      href="#"
      class="main__cabecera__idiomas__link"
      :class="{ selected: currentLocale === 'pt' }"
      @click.prevent="setLocale('pt')"
    >PT</a>
  </div>
  <div class="main__cabecera">
    <div>&nbsp;</div>
    <div class="main__cabecera__logo">
      <img class="main__cabecera__logo_img" src="/assets/logo-veltium-blanco.svg" width="262" height="68" alt="Logo Veltium"/>
    </div>
    <div>&nbsp;</div>
    <div class="main__cabecera__logo">
      <img class="main__cabecera__logo_img" src="/assets/logo-zunder-blanco.svg" width="262" height="68" alt="Logo Zunder"/>
    </div>
    <div class="main__cabecera__help">
      <img @click="ToggleHelp" class="main__cabecera__help_img" src="/assets/ico-ayuda.svg" title="Ayuda" width="45" height="45" alt="Ayuda"/>
    </div>
    <!-- Enlaces idiomas -->

  </div>

</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      currentLocale: this.$i18n.locale
    };
  },
  methods: {
    ToggleHelp() {
     // si el path de la ruta es ayuda volver al path anterior
      if (this.$route.path === '/ayuda') {
        this.$router.go(-1);
      } else {
        this.$router.push('/ayuda');
      }

    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.currentLocale = locale;

    }
  }


}
</script>

<style scoped>
</style>
